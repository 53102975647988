.header {
    position: fixed;
    display: grid;
    grid-template-areas:
        'logo space page-menu main-menu';
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: end;
    gap: 16px;
    width: 100%;
    padding: 20px 20px;
    z-index: 5;
    opacity: 1;
    transition: transform 0.5s ease-out, opacity 0.5s linear;
    pointer-events: none;
}

.header__logo {
    grid-area: logo;
    justify-self: start;
    pointer-events: auto;
    color: $color-driftwood;
}

.page-nav {
    grid-area: page-menu;
    font-family: $font-neue-haas;
    font-size: $font-12;
    font-weight: 500;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    pointer-events: auto;
    color: $color-driftwood;

    @include media('<phone') {
        display: none;
    }
}

.page-nav__list {
    list-style: none;
}

.main-nav {
    display: flex;
    align-items: center;
    gap: 10px;
    grid-area: main-menu;
    height: fit-content;
    cursor: pointer;
    font-family: $font-neue-haas;
    font-size: $font-12;
    font-weight: 500;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    pointer-events: auto;
    color: $color-driftwood;
}

.main-nav .line {
    display: flex;
}

.page-nav__item {
    overflow: hidden;
}

// animation
.header.is-hidden {
    transform: translateY(-20%);
    opacity: 0;
    pointer-events: none
}