footer {
    padding: 6px 30px 30px 30px;
    width: 100%;
    color: $color-driftwood-dark;
    @include media('<phone') {
        padding: 6px 20px 30px 20px;
     }
}

.footer__wrapper {
    width: 100%;
}

.footer__link {
    list-style: none;
    padding: 0;
}

.footer__email__copy {
    cursor: pointer;
}

.footer__linkItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15.875vw;
    line-height: 1.2;
    @include media('<laptop-l') {
        width: 16.875vw;
    }
    @include media('<tablet') {
        width: 240px;
    }
}

.footer__linkItem>.line {
    width: 100%;    
}

.footer__linkItem:last-child {
    margin-top: 1.875rem;
}

.footer__link-button {
    width: 100%;
    background: none;
    border: none;
    padding: 0;
}

.footer__linkArrow {
    position: relative;
    display: flex;
    overflow: hidden;
}

.footer__linkArrow p:last-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.footer__link-button a{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.footer__link-button a>p {
    display: inline-block;
}


.footer__linkText {
    font-family: $font-neue-haas;
    font-style: normal;
    font-weight: 400;
    font-size: $font-13;
    line-height: 1.2;
    letter-spacing: 0.05em;
    color: $color-driftwood-dark;
}

.footer__caption {
    margin-top: 150px;
    font-family: $font-neue-haas;
    font-style: normal;
    font-weight: 400;
    font-size: $font-11;
    line-height: 100%;
    letter-spacing: 0.05em;

    @include media('<phone') {
        margin-top: 110px;
     }
}