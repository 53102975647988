.section.selected-projects {
    padding-top: 31.25vw;
    color: $color-driftwood;
}

.selected-projects__caption {
    font-family: $font-neue-haas;
    font-size: $font-11;
    font-weight: 500;
    font-style: normal;
    color: $color-driftwood-dark;
}

.selected-projects__title {
    margin-top: 0.625rem;
    font-family: $font-meno;
    font-size: $font-140;
    font-weight: 300;
    font-style: normal;
    line-height: 90%;
    letter-spacing: -0.04em;
    text-transform:uppercase;
    @include media('<tablet') {
        font-size: $font-120-sectionTitles;
    }
    @include media('<phone') {
        display: flex;
        font-size: $font-140;
        flex-direction: column;
        align-items: flex-start;
    }
}

.selected-projects__title .line:last-child {
    margin-left: 2.16em;
    margin-top: -0.14em;

    @include media('<phone') {
        margin-left: 0rem;
        align-self: flex-end;
    }
}

.selected-projects__title span {
    font-style:italic;
}

.selected-projects__container {
    display: flex;
    flex-direction: column;
    gap: 17.96875vw;

    @include media('<phone') {
        gap: 70px;
    }
}

.selected-projects__item {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    width: 100%;

    &:nth-child(even) {
        flex-direction: row-reverse;
    }

    @include media('<phone') {
        flex-direction: column-reverse;
        &:first-child {
            margin-top: 50px;
        }

        &:nth-child(even) {
            flex-direction: column-reverse;
        }
    }
}

.selected-projects__item:nth-child(6) {
    margin-top: 230px;
    @include media('<tablet') {
        margin-top: 0px;
    }
    @include media('<phone') {
        margin-top: 20px;
    }
}

.selected-projects__content-wrapper  {
    width: 100%;

    @include media('<phone') {
        height: revert;

        &:nth-child(2){
            width: fit-content;
        }
    }
}

.selected-projects__content {
    display: flex;
    position: relative;
    flex-direction: row-reverse;
    margin-top: clamp(20px, 48.5vw, 99rem); // todo
    

    @include media('<phone') {
        margin-top: 20px;
    }
}

.selected-projects__content-02 {
    margin-top: clamp(20px, 24.5vw, 99rem);

    @include media('<phone') {
        margin-top: 20px;
        width: 72.4637681159vw;
        margin-left: 2.34375vw;

        & .selected-projects__itemTitleContainer {
            margin-left: 5.4em;
        } 
    }
}

.selected-projects__content-03 {
    margin-top: clamp(20px, 48vw, 99rem);

    @include media('<phone') {
        margin-top: 20px;
    }
}
.selected-projects__content-04 {
    margin-top: clamp(20px, 11.5vw, 99rem);

    @include media('<phone') {
        margin-top: 20px;
        width: 72.4637681159vw;
        margin-left: 2.34375vw;

        & .selected-projects__itemTitleContainer {
            margin-left: 5.4em;
        } 
    }
}
.selected-projects__content-05 {
    margin-top: clamp(20px, 48vw, 99rem);

    @include media('<phone') {
        margin-top: 20px;
    }
}
.selected-projects__content-06 {
    margin-top: clamp(20px, 26vw, 99rem);

    @include media('<phone') {
        margin-top: 20px;
        width: 72.4637681159vw;
        margin-left: 2.34375vw;

        & .selected-projects__itemTitleContainer {
            margin-left: 8.8em;
        } 
    }
}

.selected-projects__border {
    position: absolute;
    top: -7px;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, #F39200 75%, rgba(243, 146, 0, 0) 100%);
    transform: scaleX(0);
    transform-origin: right;
    opacity: 0.5;

    @include media('<phone') {
        width: 72.463768115942vw;

        &:first-child {
            right: 0;
            left: auto;
        }

        &:nth-child(2) {
            right: 0;
            left: auto;
        }
    }
}

.selected-projects__content .selected-projects__border {
    @include media('<phone') {
        width: 72.463768115942vw;
        right: 0;
        left: auto;
    }
}

.selected-projects__content-02 .selected-projects__border {
    @include media('<phone') {
        width: 72.463768115942vw;
        left: 0;
        right: auto;
    }
}

.selected-projects__content-03 .selected-projects__border {
    @include media('<phone') {
        width: 72.463768115942vw;
        right: 0;
        left: auto;
    }
}

.selected-projects__content-04 .selected-projects__border {
    @include media('<phone') {
        width: 72.463768115942vw;
        left: 0;
        right: auto;
    }
}

.selected-projects__content-05 .selected-projects__border {
    @include media('<phone') {
        width: 72.463768115942vw;
        right: 0;
        left: auto;
    }
}

.selected-projects__content-06 .selected-projects__border {
    @include media('<phone') {
        width: 72.463768115942vw;
        left: 0;
        right: auto;
    }
}

.selected-projects__itemTitleContainer {
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    align-items: flex-end;
    // padding-bottom: 1rem;
    // height: 72.65625vw;
}

.selected-projects__itemTitleNumber {
    font-family: $font-tsukuoldminD;
    font-size: $font-12;
    color: $color-driftwood-light;
}

.selected-projects__itemTitleNumber span{
    font-family: YakuHanMP;
}

.selected-projects__itemTitle {
    display: flex;
    flex-direction: column;
    margin-top: $space-80;
    font-family: $font-meno;
    font-size: $font-110;
    font-weight: 300;
    font-style: normal;
    line-height: 1;
    // line-height: 5.8rem;
    letter-spacing: -0.04em;
    text-transform:uppercase;
    // text-align: right;
    writing-mode: vertical-rl;
}

// タイトルスタイル
.selected-projects__item01 .selected-projects__itemTitle {
    @include media('<phone') {
        margin-right: -0.13em;
    }
    & .line:last-child {
        margin-top: 1.64em;
        margin-right: -0.25em;
    }
}

.selected-projects__item02 .selected-projects__itemTitle {
    @include media('<phone') {
        margin-right: -0.2em;
    }
    & .line:nth-child(2) {
        margin-top: 1.27em;
        margin-right: -0.25em;
    }
    & .line:last-child {
        margin-top: 2.25em;
        margin-right: -0.25em;
    }
}

.selected-projects__item03 .selected-projects__itemTitle {
    @include media('<phone') {
        margin-right: -0.13em;
    }
    & .line:last-child {
        margin-top: 0.51em;
        margin-right: -0.25em;
    }
}

.selected-projects__item04 .selected-projects__itemTitle {
    @include media('<phone') {
        margin-right: -0.2em;
    }
    & .line:nth-child(2) {
        margin-top: 1.03em;
        margin-right: -0.25em;   
    }
    & .line:last-child {
        margin-top: 3.94em;
        margin-right: -0.25em;
    }
}

.selected-projects__item05 .selected-projects__itemTitle {
    @include media('<phone') {
        margin-right: -0.13em;
    }
    & .line:last-child {
        margin-top: 0.8em;
        margin-right: -0.25em;
    }
}

.selected-projects__item06 .selected-projects__itemTitle {
    @include media('<phone') {
        margin-right: -0.2em;
    }
    & .line:last-child {
        margin-top: 2.02em;
        margin-right: -0.25em;
    }
}


.selected-projects__itemTitle p span {
    font-style:italic;
}

.selected-projects__description-container {
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 3.90625vw;
    writing-mode: vertical-rl;
    @include media('<phone') {
        display: none;
    }
}

.selected-projects__item01 .selected-projects__description-container {
    @media (max-width: 46.25rem) {
        margin-right: -30px;
        margin-top: 80px;
      }
}

.selected-projects__item03 .selected-projects__description-container {
    @media (max-width: 46.25rem) {
        margin-right: -30px;
        margin-top: 80px;
      }
}

.selected-projects__item05 .selected-projects__description-container {
    @media (max-width: 46.25rem) {
        margin-right: -30px;
        margin-top: 80px;
      }
}

.selected-projects__descriptions {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1vw;
    padding-top: 29.5em;
    color: $color-driftwood-dark;
    @include media('<laptop-l') {
        padding-top: 23em;
    }
    @include media('<laptop') {
        padding-top: 21em;
    }
    @include media('<tablet') {
        padding-top: 13.6em;
    }
}

.selected-projects__item02 .selected-projects__descriptions {
    padding-top: 28em;
    @include media('<laptop-l') {
        padding-top: 23em;
    }
    @include media('<laptop') {
        padding-top: 20em;
    }
    @include media('<tablet') {
        padding-top: 16.5em;
    }
}

.selected-projects__item03 .selected-projects__descriptions {
    padding-top: 20em;
    @include media('<laptop-l') {
        padding-top: 16.6em;
    }
    @include media('<laptop') {
        padding-top: 14.6em;
    }
    @include media('<tablet') {
        padding-top: 10em;
    }
}

.selected-projects__item04 .selected-projects__descriptions {
    padding-top: 41.5em;
    @include media('<laptop-l') {
        padding-top: 35em;
    }
    @include media('<laptop') {
        padding-top: 29.5em;
    }
    @include media('<tablet') {
        padding-top: 19em;
    }
}

.selected-projects__item05 .selected-projects__descriptions {
    padding-top: 23em;
    @include media('<laptop-l') {
        padding-top: 18.8em;
    }
    @include media('<laptop') {
        padding-top: 16em;
    }
    @include media('<tablet') {
        padding-top: 10.5em;
    }
}

.selected-projects__item06 .selected-projects__descriptions {
    padding-top: 24em;
    @include media('<laptop-l') {
        padding-top: 20em;
    }
    @include media('<laptop') {
        padding-top: 18em;
    }
    @include media('<tablet') {
        padding-top: 11.5em;
    }
}


.selected-projects__description {
    font-family: $font-tsukuoldminB;
    font-size: $font-15;
    font-style: normal;
    line-height: 1.9rem;
    letter-spacing: 0.07rem;
    white-space: nowrap;
}

.selected-projects__description:nth-child(2) {
    padding-top: 5.3em;

    & p:nth-child(2) {
        margin-top: 4.8em;
    }
}

.selected-projects__item02 .selected-projects__description:nth-child(2) {
    padding-top: 5em;

    & p:nth-child(2) {
        margin-top: 4.8em;
    }
}

.selected-projects__item03 .selected-projects__description:nth-child(2) {
    padding-top: 5.3em;

    & p:nth-child(2) {
        margin-top: 4.8em;
    }
}

.selected-projects__item04 .selected-projects__description:nth-child(2) {
    padding-top: 5.3em;

    & p:nth-child(2) {
        margin-top: 4.8em;
    }
}

.selected-projects__item05 .selected-projects__description:nth-child(2) {
    padding-top: 5.3em;

    & p:nth-child(2) {
        margin-top: 4.8em;
    }
}

.selected-projects__item06 .selected-projects__description:nth-child(2) {
    padding-top: 6.6em;

    & p:nth-child(2) {
        margin-top: 4.8em;
    }
}

.selected-projects__description span {
    display: block;
}



.selected-projects__image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: $space-30;
    @include media('<phone') {
        width: fit-content;
     }
}

.selected-projects__image-wrapper-01 {
    @include media('<phone') {
        align-self: flex-end;
     }
}

.selected-projects__image-wrapper-02 {
    align-items: flex-start;
    @include media('<phone') {
        align-self: flex-start;
     }
}

.selected-projects__image-wrapper-03 {
    @include media('<phone') {
        align-self: flex-end;
     }
}

.selected-projects__image-wrapper-04 {
    align-items: flex-start;
    @include media('<phone') {
        align-self: flex-start;
     }
}

.selected-projects__image-wrapper-05 {
    @include media('<phone') {
        align-self: flex-end;
     }
}

.selected-projects__image-wrapper-06 {
    align-items: flex-start;
    @include media('<phone') {
        align-self: flex-start;
     }
}

.selected-projects__image-container {
    position: relative;
    width: 56.015vw;
    height: 72.65625vw;
    opacity: 0;
    cursor: none;

    @include media('<phone') {
        // 310 402 - 414
        width: 74.8792270531401vw;
        height: 97.1014492753623vw;
    }
}

.selected-projects__image-container-02 {
    position: relative;
    width: 39.296875vw;
    height: 51.015625vw;

    @include media('<phone') {
        // 300 390 - 414
        width: 72.463768115942vw;
        height: 94.2028985507246vw;
    }
}

.selected-projects__image-container-03{
    position: relative;
    width: 56.015625vw;
    height: 72.65625vw;

    @include media('<phone') {
        // 310 402 - 414
        width: 74.8792270531401vw;
        height: 97.1014492753623vw;
    }
}

.selected-projects__image-container-04{
    position: relative;
    width: 39.296875vw;
    height: 51.015625vw;

    @include media('<phone') {
        // 300 390 - 414
        width: 72.463768115942vw;
        height: 94.2028985507246vw;
    }
}

.selected-projects__image-container-05{
    position: relative;
    width: 56.015625vw;
    height: 72.65625vw;

    @include media('<phone') {
        // 310 402 - 414
        width: 74.8792270531401vw;
        height: 97.1014492753623vw;
    }
}

.selected-projects__image-container-06{
    position: relative;
    width: 39.296875vw;
    height: 51.015625vw;

    @include media('<phone') {
        // 300 390 - 414
        width: 72.463768115942vw;
        height: 94.2028985507246vw;
    }
}


.selected-projects__image-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image:linear-gradient(90deg, rgba(24, 24, 24, 0.95) 0%, rgba(24, 24, 24, 0) 49.99%, rgba(24, 24, 24, 0.95) 100%);
}

.selected-projects__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.selected-projects__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.selected-projects__image img:last-child {
    position: absolute;
    top: 0;
    left: 0;
}

.selected-projects__captions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 10px;
    color: $color-driftwood;

    @include media('<phone') {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}

.selected-projects__captions-01 {
    @include media('<phone') {
        align-self: flex-start;
    }
}

.selected-projects__captions-02 {
    align-items: flex-start;

    @include media('<phone') {
        align-items: center;
        margin-right: 0em;
    }
}

.selected-projects__captions-03 {
    @include media('<phone') {
        align-self: flex-start;
    }
}

.selected-projects__captions-04 {
    align-items: flex-start;

    @include media('<phone') {
        margin-right: 0em;
        align-items: center;
    }
}

.selected-projects__captions-05 {

    @include media('<phone') {
        align-self: flex-start;
    }
}

.selected-projects__captions-06 {
    align-items: flex-start;

    @include media('<phone') {
        margin-right: 0em;
        align-items: center;
    }
}

.selected-projects__number {
    font-family: $font-meno;
    font-size: $font-140;
    font-weight: 400;
    font-style: normal;
    line-height: 7.875rem;
    letter-spacing: -2%;
    text-transform:uppercase;
}

.selected-projects__genre {
    font-family: $font-neue-haas;
    font-size: $font-11;
    font-weight: 400;
    font-style: normal;
    line-height: 120%;
    letter-spacing: 0.03125rem;
    text-transform:uppercase;
}

.selected-projects__genre span {
    display: block;
}

// mobile
.selected-projects__arrow-mobile {
    display: none;
    @include media('<phone') {
        display: revert;
        margin-top: 2%;
    }
}

// pointer
#pointer {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 7.8125vw;
    height: 7.8125vw;
    z-index: 10;
    contain: layout style size;
    pointer-events: none;
    opacity: 0;
    @include media('<tablet') {
        display: none;
    }
}

#pointer:before {
    content: "";
    position: absolute;
    display: block;
    width: 7.8125vw;
    height: 7.8125vw;
    transform: scale(1);
    background: #0F0F0F;
    border-radius: 50%;
}

.pointer__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3.125vw;
    height: 3.125vw;
    background: none;
    transform: translate(-50%, -50%);
}