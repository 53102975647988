.principles {
    width: 100vw;
    padding: 14.84375vw 0 0 0;
    color: $color-driftwood;
    overflow: hidden;
    @include media('<tablet') {
        padding: 0 0 0 0;
        margin-top: -450px;
    }
    @include media('<phone') {
        padding: 14.84375vw 0 0 0;
        margin-top: 0;
    }
}

.principles__wrapper {
    width: 100%;
}

.principles__wrapper .principles__card hr {
    height: 1px;
    background: linear-gradient(90deg, #F39200 75%, rgba(157, 122, 75, 0) 100%);
    opacity: 0.5;
    border: none;
}

.principles__top {
    width: 100%;
    padding: 0px 30px;
}

.principles__titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: fit-content;
}

.principles__titleCaption {
    font-family: $font-neue-haas;
    font-style: normal;
    font-weight: 400;
    font-size: $font-11;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: $color-driftwood-dark;
}

.principles__title {
    color: $color-driftwood;
    font-family: $font-meno;
    font-style: normal;
    font-weight: 300;
    font-size: $font-140;
    line-height: 94%;
    letter-spacing: -0.03em;
    @include media('<tablet') {
        font-size: $font-120-sectionTitles;
    }
    @include media('<phone') {
        font-size: $font-140;
    }
}

.principles__title .line p {
    display: block;
    width: fit-content;
}

.principles__title .line:last-child {
    margin-left: 1.85em;
    margin-top: -0.2em;

    @include media('<phone') {
        margin-left: 1.85em;
        margin-top: -0.15em;
    }
}

.principles__title .line p span {
    font-style: italic;
}

.principles__cardsContainer {
    position: relative;
    width: 100%;
    height: 216vh;
    overflow: hidden;
    // @media (max-height: 28.75rem) {
    //     height: revert;
    //   }
    @include media('<tablet') {
        margin-top: 28px;
        height: revert;
    }
}

.principles__card {
    padding: 10px 30px 0px 30px;
    width: 100%;
    height: 88vh;
    // padding: 10px 90px 50px 110px;
    opacity: 1;
    background:  #000;

    @include media('<tablet') {
        height: 60vh;
        height: 60dvh;
        min-height: 600px;
    }

    @include media('<phone') {
        // height: 88vh;
        // height: 88dvh;
        height: revert;
        min-height: revert;
        padding: 10px 20px 50px 20px;
    }
    @media (max-height: 28.75rem) {
        min-height: 640px;
      }
}

.principles__card-1 {
    position: absolute;
    top: 0;
    left: 0;
    // @media (max-height: 28.75rem) {
    //     position: revert;
    //     top: 0;
    //   }
    @include media('<tablet') {
        position: revert;
        top: 0;
    }
}

.principles__card-2 {
    position: absolute;
    // top: 88vh;
    // top: 85vh;
    top: 87.25vh;
    left: 0;
    z-index: 5;
    // @media (max-height: 28.75rem) {
    //     position: revert;
    //     top: 0;
    //   }
    @include media('<tablet') {
        position: revert;
        top: 0;
    }
}

.principles__card-3 {
    position: absolute;
    // top: 92vh;
    top: 90.5vh;
    // top: 89vh;
    left: 0;
    z-index: 10;
    // @media (max-height: 28.75rem) {
    //     position: revert;
    //     top: 0;
    //   }
    @include media('<tablet') {
        position: revert;
        top: 0;
    }
}

.principles__card-4 {
    position: absolute;
    // top: 96vh;
    top: 93.75vh;
    left: 0;
    z-index: 15;
    // @media (max-height: 28.75rem) {
    //     position: revert;
    //     top: 0;
    //   }
    @include media('<tablet') {
        position: revert;
        top: 0;
    }
}

.card__header {
    width: 100%;
    // height: 4vh;
    padding:  6px 0 34px 0;
    @include media('<phone') {
        padding:  6px 0 6px 0;
    }
}

.card__headerNumber {
    font-family: $font-tsukuoldminD;
    font-style: normal;
    font-weight: 600;
    font-size: $font-10;
    line-height: 142%;
    text-align: left;
}

.card__contents {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    height: 80.875vh;
    @include media('<tablet') {
        height: 100%;
    }
    @include media('<phone') {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        // gap: 60px;
        // height: 80.875dvh;
        height: revert;
        gap: 3vh;
    }
    // @media (max-height: 28.75rem) {
    //     height: 100%;
    //   }
    // display: flex;
    // justify-content: space-between;
    // flex-direction:row-reverse;
}

.card__contents-right {
    display: flex;
    flex-direction:row-reverse;
    padding-right: 4.6875vw;
    gap: 6vw;
    grid-area: card__contents__right;
    margin-left: -12vw;
    @include media('<tablet') {
        margin-left: -80vw;
    }
    @include media('<phone') {
        padding-right: 0;
        align-self: flex-end;
    }
}

.card__upTitles {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.card__upTitleText {
    font-family: $font-tsukuoldminM;
    font-style: normal;
    font-weight: 500;
    font-size: $font-150;
    line-height: 100%;
    // opacity: 0;
}

.card__upTitleCaption {
    margin-top: 1.25rem;
    font-family: $font-neue-haas;
    font-style: normal;
    font-weight: 400;
    font-size: $font-13;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: $color-driftwood-dark;
}

.card__upDescriptions {
    // margin-top: 6.25rem;
    margin-top: 7.9em;
    font-family: $font-tsukuoldminB;
    font-style: normal;
    font-weight: 600;
    font-size: $font-15;
    line-height: 235%;
    letter-spacing: 0.1em;
    // font-feature-settings: 'pkna' on, 'palt' on, 'vrt2' on, 'hkna' on;
    writing-mode: vertical-rl;
    color: $color-driftwood-dark;
    @include media('<laptop-l') {
        margin-top: 6.5em;
    }
    @include media('<laptop') {
        margin-top: 5.9em;
    }
    @include media('<phone') {
        margin-top: 7.5em;
        // margin-top: 2vw;
        // margin-top: 5.5em;
        line-height: 235%;
        font-size: 0.75rem;
    }
}

.card__upDescription2 {
    // margin-top: 6.6rem;
    margin-top: 7em;
    // margin-right: 2.6rem;
    margin-right: 4.6875vw;
    @include media('<phone') {
        margin-top: 7.5em;
        // margin-top: 6.2em;
        margin-right: 2em;
    }
}

.card__contents-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 6.25vw;
    padding-bottom: 110px;
    gap: 40px;
    grid-area: card__contents__left;
    @include media('<phone') {
        padding-left: 0;
        width: 100%;
        padding-bottom: 0px;
        padding-top: 40px;
    }
}

.card__imageContainer {
    position: relative;
    display: flex;
    gap: 16px; //todo
    // width: 7.8125vw;
    // height: 10.15625vw;
    width: 6.25vw;
    height: 8.125vw;
    @include media('<phone') {
        display: none;
    }
}

.card__image-1Container, .card__image-2Container, .card__image-3Container {
    position: absolute;
    // width: 10.15625vw;
    // height: 13.203125vw;
    // width: 7.8125vw;
    // height: 10.15625vw;
    width: 6.25vw;
    height: 8.125vw;
    background: #181818;
}

.card__image-1,.card__image-2, .card__image-3,.card__image-4 {
    width: 100%;
    height: 100%;
}

.card__image-1 img,.card__image-2 img, .card__image-3 img,.card__image-4 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card__image-1Container {
    z-index: 3;
    @media (max-height: 40rem) {
        transform: translate3d(0.5vw, 0vw, 1vw)
      }
}

.card__image-2Container {
    z-index: 2;
    @media (max-height: 40rem) {
        transform: translate3d(5.21875vw, 0vw, 1vw)
      }
}

.card__image-3Container {
    z-index: 1;
    @media (max-height: 40rem) {
        transform: translate3d(9.43625vw, 0vw, 1vw)
      }
}

.card__bottomTitles {
    width: fit-content;
    @include media('<phone') {
        align-self: flex-start;
    }
}

.card__bottomTitlesContainer {
    @include media('<phone') {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.card__bottomTitleCaption {
    font-family: $font-neue-haas;
    font-style: normal;
    font-weight: 400;
    font-size: $font-10;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: $color-driftwood-dark;
}

.card__bottomTitle {
    font-family: $font-meno;
    font-style: normal;
    font-weight: 400;
    font-size: $font-90;
    line-height: 100%;
    letter-spacing: -0.04em;
    font-feature-settings: 'liga' off, 'calt' off;
}

.card__bottomTitle span {
    font-style: italic;
}

.card__bottomDescriptions {
    margin-left: 8rem;
    margin-top: 5px;
    white-space: nowrap;
    @include media('<tablet') {
        margin-left: 6rem;
    }
    @include media('<phone') {
        // margin-left: 0;
        margin-left: 4.5em;
        width: 80%;
        white-space: normal;
    }
}

.card-2__bottomDescriptions {
    @include media('<phone') {
        // margin-left: 0;
        margin-left: 4.5em;
        width: 80%;
    }
}

.card-3__bottomDescriptions {
    margin-left: 11.7rem;
    @include media('<tablet') {
        margin-left: 6rem;
    }
    @include media('<phone') {
        // margin-left: 0;
        margin-left: 4.5em;
        width: 80%;
    }
}

.card-4__bottomDescriptions {
    margin-left: 17.4rem;
    @include media('<tablet') {
        margin-left: 6rem;
    }
    @include media('<phone') {
        // margin-left: 0;
        margin-left: 4.5em;
        width: 85%;
    }
}

.card__bottomDescription {
    font-family: $font-neue-haas;
    font-style: normal;
    font-weight: 400;
    font-size: $font-13;
    line-height: 130%;
    letter-spacing: 0.05em;
    color: $color-driftwood-dark;
}

.card__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-black;
    opacity: 0;
    pointer-events: none;
    // @media (max-height: 28.75rem) {
    //     display: none;
    //   }
    @include media('<tablet') {
        display: none;
    }
}

