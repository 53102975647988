.global-menu {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 17.5vh  0 5vh 0;
    color: $color-driftwood;
    z-index: 9999999;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    @include media('<phone') {
        padding: 17.5dvh  0 5dvh 0;
        height: 100dvh;
    }
}

.global-menu__container {
    position: relative;
    width: fit-content;
    width: 100%;
    height: 100%;
}

.global-menu__part {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.global-menu__closeButton {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 10px;
    top: 20px;
    right: 20px;
    padding: 0 0 0 0;
    cursor: pointer;
    background: none;
    border: none;
    font-family: $font-neue-haas;
    font-style: normal;
    font-weight: 450;
    font-size: $font-12;
    line-height: 1.3;
    letter-spacing: 0.04em;
    color: $color-driftwood;
}

.global-menu__list {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: fit-content;
    height: 100%;
    padding: 0;
    list-style: none;
}

.global-menu__item {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    opacity: 0.5;
    transition: opacity 0.4s ease;
    overflow: hidden;
    @include media('<phone') {
        width: 166px;
    }
}

.global-menu__item:hover {
    opacity: 1;
}

.global-menu__line {
    position: absolute;
    width: 1px;
    height: 100%;
    background: linear-gradient(180deg, #F39200 75%, rgba(243, 146, 0, 0) 100%);
}

.global-menu__itemContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    padding: 0 8px;
}

.global-menu__titles {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 50px;
    width: 100%;
    height: 100%;
    @include media('<phone') {
        gap: 3.6dvh;
    }
}

.global-menu__itemTitleNumber{
    font-family: $font-tsukuoldminD;
    font-size: $font-12;
    font-weight: 600;
}

.global-menu__itemTitle {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    font-family: $font-meno;
    font-size: $font-104;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -.04em;
    line-height: 1;
    writing-mode: vertical-rl;
    @media (max-height: 48.75rem) {
        font-size: $font-90-menu;
      }
    @include media('<phone') {
        margin-left: 0;
        @media (max-height: 42rem) {
            font-size: $font-90;
          }
    }
}

.global-menu__itemTitle>.line:nth-child(2) {
    margin-right: -0.25em;
}

.global-menu-2 .global-menu__itemTitle .line>p span,
.global-menu-3 .global-menu__itemTitle .line>p span {
    font-style: italic;
}

// メニュ−１
.global-menu-1 .global-menu__item-1 .global-menu__itemTitle .line{
    &:last-child {
        margin-top: 1.55em;
    }
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(5){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(3){
            font-style: italic;
        }
        &>span:nth-child(6){
            font-style: italic;
        }
    }
}

// メニュ−2
.global-menu-1 .global-menu__item-2 .global-menu__itemTitle .line{
    &:last-child {
        margin-top: 0.9em;
    }
    &:first-child>p{
        &>span:nth-child(3){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(3){
            font-style: italic;
        }
    }
}

// メニュ−3
.global-menu-1 .global-menu__item-3 .global-menu__itemTitle .line{
    &:last-child {
        margin-top: 1.95em;
    }
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(4){
            font-style: italic;
        }
        &>span:nth-child(7){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(3){
            font-style: italic;
        }
        &>span:nth-child(4){
            font-style: italic;
        }
    }
}

// メニュ−4
.global-menu-1 .global-menu__item-4 .global-menu__itemTitle .line{
    &:last-child {
        margin-top: 1.12em;
    }
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(3){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(1){
            font-style: italic;
        }
        &>span:nth-child(3){
            font-style: italic;
        }
        &>span:nth-child(5){
            font-style: italic;
        }
    }
}

// メニュ−5
.global-menu-1 .global-menu__item-5 .global-menu__itemTitle .line{
    &:last-child {
        margin-top: 0.83em;
    }
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(3){
            font-style: italic;
        }
    }
}

// メニュ−１
.global-menu-2 .global-menu__item-1 .global-menu__itemTitle .line,
.global-menu-3 .global-menu__item-1 .global-menu__itemTitle .line
{
    &:last-child {
        margin-top: 1.55em;
    }
    &:first-child>p{
        &>span{
            font-style: italic;
        }
    }
}

// メニュ−2
.global-menu-2 .global-menu__item-2 .global-menu__itemTitle .line,
.global-menu-3 .global-menu__item-2 .global-menu__itemTitle .line
{
    &:last-child {
        margin-top: 0.9em;
    }
    &:first-child>p{
        &>span{
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span{
            font-style: italic;
        }
    }
}

// メニュ−3
.global-menu-2 .global-menu__item-3 .global-menu__itemTitle .line,
.global-menu-3 .global-menu__item-3 .global-menu__itemTitle .line
{
    &:last-child {
        margin-top: 1.95em;
    }
    &:first-child>p{
        &>span{
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span{
            font-style: italic;
        }
    }
}

// メニュ−4
.global-menu-2 .global-menu__item-4 .global-menu__itemTitle .line,
.global-menu-3 .global-menu__item-4 .global-menu__itemTitle .line
{
    &:last-child {
        margin-top: 1.12em;
    }
    &:first-child>p{
        &>span{
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span{
            font-style: italic;
        }
    }
}

// メニュ−5
.global-menu-2 .global-menu__item-5 .global-menu__itemTitle .line,
.global-menu-3 .global-menu__item-5 .global-menu__itemTitle .line
{
    &:last-child {
        margin-top: 0.83em;
    }
    &:first-child>p{
        &>span{
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span{
            font-style: italic;
        }
    }
}

.global-menu__itemCaptions{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-end;
    writing-mode: vertical-rl;
    color: $color-driftwood;
    @include media('<phone') {
        margin-right: -24px;
    }
}

.global-menu__itemChapter {
    font-family: $font-neue-haas;
    font-style: normal;
    font-weight: 450;
    font-size: $font-11;
    line-height: 1;
    /* or 13px */
    letter-spacing: 0.05em;
}

.global-menu__itemCaption{
    margin-right: 0.375rem;
    font-family: $font-tsukuoldminB;
    font-style: normal;
    font-weight: 600;
    font-size: $font-15;
    line-height: 1;
    /* or 107% */
    
    letter-spacing: 0.05em;
    // font-feature-settings: 'pkna' on, 'palt' on, 'vrt2' on, 'hkna' on;
}

.global-menu__image{
    display: none;
}
