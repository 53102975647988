.lastMessage {
    width: 100%;
    @include media('<tablet') {
        margin-top: 200px;
     }
    // @include media('<phone') {
    //     margin-top: -15vh;
    //     margin-top: -15dvh;
    //  }

    //  @media (max-width: 34.375rem) and (min-height: 46.875rem) {
    //     margin-top: -72vh;
    //   }
    //   @media (max-height: 28.75rem) {
    //     margin-top: 200px;
    //   }
}

.lastMessage__text {
    font-family: $font-meno;
    font-style: normal;
    font-weight: 400;
    font-size: $font-70;
    line-height: 80%;
    letter-spacing: -0.04em;
    color: $color-driftwood;
}

.lastMessage__text .line p span {
    font-style: italic;
}

.lastMessage__text .line:nth-child(2) {
    margin-left: 1.43em;
    @include media('<phone') {
       margin-left: 0.6em;
    }
}

.lastMessage__text .line:nth-child(3) {
    margin-left: 0.57em;
    @include media('<phone') {
        margin-left: 0.4em;
     }
}
