$color-driftwood: #884E00;
$color-driftwood-dark: #653304;
$color-driftwood-light: #F39200;
$color-gray: #F2F2F2;
$color-white: #DCD7D1;
$color-black: #000000;
$color-darkgray: #181818;

$font-neue-haas: 'neue-haas-grotesk-display', serif;
$font-meno: 'meno-banner-extra-condensed', serif;
$font-tsukuoldmin: 'TsukuAOldMinPr6-B', serif;
$font-tsukuoldminR: 'FOT-筑紫Aオールド明朝 Pr6 R', serif;
$font-tsukuoldminM: 'FOT-筑紫Aオールド明朝 Pr6 M', serif;
$font-tsukuoldminD: 'FOT-筑紫Aオールド明朝 Pr6 D', serif;
$font-tsukuoldminB: 'FOT-筑紫Aオールド明朝 Pr6 B', serif;

$font-10: clamp(0.625rem, 0.06vw + 0.625rem, 99rem);
$font-10: 0.625rem;
$font-11: clamp(0.68755rem, 0.078125vw + 0.625rem, 99rem);
$font-12: clamp(0.755rem, 0.15625vw + 0.625rem, 99rem);
$font-13: clamp(0.8125rem, 0.234375vw + 0.625rem, 99rem);
$font-14: clamp(0.875rem, 0.3125vw + 0.625rem, 99rem);
$font-15: clamp(0.9375rem, 0.390625vw + 0.625rem, 99rem);
$font-17: clamp(1rem, 0.078125vw + 1rem, 99rem);
$font-30: clamp(1.75rem, 1.09375vw + 1rem, 99rem);
$font-30-static: clamp(1.75rem, 1.09375vw + 1rem, 1.875rem);
$font-40-static: 2.5rem;
$font-70-archives: clamp(3.5rem, 4.21875vw + 1rem, 4.375rem);
$font-70: clamp(3.5rem, 4.21875vw + 1rem, 99rem); // 56-70 last message
$font-80: clamp(4.375rem, 5vw + 1rem,99rem); // 70-80 archives タイトル
$font-80-static: 5rem;
$font-80-archives:clamp(4.375rem, 5vw + 1rem,5.625rem); // 70-80 archives タイトル
$font-90: clamp(4rem, 5.78125vw + 1rem, 99rem); // 64-90 principles 英文タイトル
$font-90-menu: clamp(4rem, 5.78125vw + 1rem, 5.625rem); // 64-90 principles 英文タイトル
$font-104: clamp(5rem, 6.875vw + 1rem, 99rem); // 80-104 global-menu タイトル
$font-110: clamp(4.375rem, 7.34375vw + 1rem, 99rem);  // 70-110 selected projectsタイトル
$font-120detail: clamp(6.25rem, 8.125vw + 1rem, 99rem);  // 100-120 selected projectsタイトル
$font-120-sectionTitles: 7.5rem;  // 100-120 selected projectsタイトル
$font-140: clamp(5.625rem, 9.6875vw + 1rem, 99rem); // 90-140 セクションタイトル
$font-150: clamp(6rem, 10.46875vw + 1rem, 99rem); // 90-140 セクションタイトル
$font-160: clamp(6rem, 11.25vw + 1rem, 99rem); // 96-160 principles日本語タイトル
$font-160detail: clamp(6.25rem, 11.25vw + 1rem, 99rem); // 100-160 principles日本語タイトル
$font-276: clamp(11.25rem, 20.3125vw + 1rem, 99rem); // Heroタイトル
$font-276-static: clamp(11.25rem, 33.8547vw + 1rem, 147.25rem); 

$space-10: 0.78125vw;
$space-30: 2.34375vw;
$space-100: 7.81255vw;
$space-40: 3.125vw;
$space-80: 6.25vw;


