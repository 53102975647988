.message {
    padding-top: 7vw;
    padding-bottom: 0;
    width: 100%;
    @include media('<tablet') {
        padding-top: 5.625rem;
    }
    @include media('<phone') {
        padding-top: 5.625rem;
    }
}

.message__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    @include media('<tablet') {
        display: flex;
        flex-direction: column;
        gap: 110px;
    }
    @include media('<phone') {
        gap: 150px;
    }
}

.message__description-items-mobile {
    text-align: left;
    font-family: $font-neue-haas;
    font-size: $font-14;
    font-weight: 500;
    font-style: normal;
    line-height: 130%;
    letter-spacing: 0.5px;
    display: none;
    @include media('<phone') {
        display: revert;
    }
}

.message__description-container {
    display: flex;
    justify-content: flex-end;
    grid-area: message;
    font-family: $font-neue-haas;
    font-size: $font-14;
    font-weight: 500;
    font-style: normal;
    line-height: 130%;
    letter-spacing: 0.5px;
    color: $color-driftwood;
    @include media('<phone') {
        text-align: left;
    }
    
}

.message__description-items {
    width: fit-content;
    margin-right: 10.5em;
    font-family: $font-neue-haas;
    font-size: $font-14;
    font-weight: 500;
    color: $color-driftwood;
    font-style: normal;
    line-height: 130%;
    letter-spacing: 0.5px;
    @include media('<laptop-l') {
        margin-right: 3.5em;
    }
    @include media('<laptop') {
        margin-right: 0.5em;
    }
    @include media('<phone') {
        display: none;
    }
} 

.message__description-items .line {
    overflow: hidden;
}

.message__vertical-descriptions-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 7vw;
    grid-area: vertical-message;
    writing-mode: vertical-rl;

    font-family:$font-tsukuoldminB;
    font-size: $font-15;
    font-style: normal;
    
    letter-spacing: 0.07rem;
    color: $color-driftwood;
    transform: translate(-50%, 0);

    @include media('<tablet') {
        justify-content: center;
        line-height: 1rem;
        transform: translate(0, 0);
    }
} 

.message__vertical-description-item {
    white-space: nowrap;
}

.message__vertical-description-item:first-child {
    & .line {
        line-height: 2.5;

        &:nth-child(3) {
            margin-top: 10.2em;
        }
    }

    @include media('<phone') {
        & .line {
            line-height: 2;

            &:nth-child(3) {
                margin-top: 8.9em;
            }
        }
    }
}

.message__vertical-description-item:nth-child(2) {
    padding-top: 9.9em;
    line-height: 2.3;
    & .line {
        display: block;
        margin-top: 3.85em;

        &:nth-child(2) {
            margin-top: 8.4em;
        }
        &:last-child {
            margin-top: 13em;
        }
    }
    @include media('<phone') {
        padding-top: 7.8em;
        & .line {
            line-height: 2;

            &:nth-child(2) {
                margin-top: 7.3em;
            }
            &:last-child {
                margin-top: 11em;
            }
        }
    }
}

.message__vertical-description-item:last-child {
    padding-top: 22em;
    line-height: 2.3;
    & .line {
        display: block;
        margin-top: 4.3em;

        &:last-child {
            margin-top: 13.5em;
        }
    }
    @include media('<phone') {
        padding-top: 17.7em;
        & .line {
            line-height: 2;

            &:last-child {
                margin-top: 11.5em;
            }
        }
    }
}


