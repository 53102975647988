.inspirations {
    @include media('<tablet') {
        padding: 0px 0;
    }
}

.inspirations__wrapper {
    position: relative;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    color: $color-driftwood;
    overflow: hidden;
    height: 100vh;
}

.inspirations__titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: fit-content;
    margin-top: 20%;
}

.inspirations__titleCaption {
    font-family: $font-neue-haas;
    font-style: normal;
    font-weight: 400;
    font-size: $font-11;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: $color-driftwood-dark;
}

.inspirations__title {
    font-family: $font-meno;
    font-style: normal;
    font-weight: 400;
    font-size: $font-140;
    line-height: 94%;
    letter-spacing: -0.04em;
    @include media('<tablet') {
        font-size: $font-120-sectionTitles;
    }
    @include media('<phone') {
        font-size: $font-140;
    }
}

.inspirations__title p {
    display: block;
    width: fit-content;
}

.inspirations__title .line:last-child {
    margin-left: 2.12em;
    margin-top: -0.22em;

    @include media('<phone') {
        margin-left: 2.1em;
        margin-top: -0.18em;
    }
}

.inspirations__title .line span {
    font-style: italic;
}

.inspirations__gallery {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(50,2%);
	grid-template-rows: repeat(75,1.33%);
    width: 100%;
    height: 306.625vh;
    z-index: -1;

    @include media('<tablet') {
        height: 150vh;
    }
}

.inspirations__item {
    position: relative;
}

.inspirations__item.inspirations__item-1 {
    // 386 436
    grid-area: 9/10;
    width: 30.15625vw;
    height: 34.0625vw;
    min-height: 192px;
    min-width: 170px;
}

.inspirations__item.inspirations__item-2 {
    // 200 268
    grid-area: 21/36;
    width: 15.625vw;
    height: 20.9375vw;
    min-height: 120px;
    min-width: 90px;
}

.inspirations__item.inspirations__item-3 {
    // 160 190
    grid-area: 36/1;
    width: 12.5vw;
    height: 14.84375vw;
    min-height: 106px;
    min-width: 90px;
}
.inspirations__item.inspirations__item-4 {
    // 200 270
    grid-area: 45/42;
    width: 15.625vw;
    height: 21.09375vw;
    min-height: 135px;
    min-width: 100px;
}
.inspirations__item.inspirations__item-5 {
    // 410 280
    grid-area: 48/6;
    width: 32.03125vw;
    height: 21.875vw;
    min-height: 145px;
    min-width: 205px;
}
.inspirations__item.inspirations__item-6 {
    // 270 170
    grid-area: 61/33;
    width: 21.09375vw;
    height: 13.28125vw;
    min-height: 56px;
    min-width: 90px;
}
.inspirations__item.inspirations__item-7 {
    // 510 310
    grid-area: 70/18;
    width: 39.84375vw;
    height: 24.21875vw;
    min-height: 160px;
    min-width: 263px;
}

.inspirations__imageWrapper {
    width: 100%;
    height: 100%;
}

.inspirations__imageWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

// .inspirations__item::after {
//     content: '';
//     position: absolute;
//     top: -1px;
//     left: -1px;
//     width: 100.5%;
//     height: 100.5%;
//     background-image:linear-gradient(0deg, rgba(24, 24, 24, 0.95) 0%, rgba(24, 24, 24, 0) 49.99%, rgba(24, 24, 24, 0.95) 100%);
// }