*, *::before, *::after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
  }

  html {
    overflow: hidden;
    position: fixed;
  }

  html, body {
    height: 100%;
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
  }
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
  }
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }
  input, button, textarea, select {
    font: inherit;
  }
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }
  #root, #__next {
    isolation: isolate;
  }

  .visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }

  html {
    font-size: 1em;
    background-color: #000;
  }

  body {
    font-family:
      "Helvetica Neue",
      Arial,
      "Hiragino Kaku Gothic ProN",
      "Hiragino Sans",
      Meiryo,
      sans-serif;
  }

  .wrapper
  {
    overflow-x: hidden;
  }

  main {
    min-height: 100%;
    color: $color-white;
    isolation: isolate;
    z-index: 0;
  }

  a,a:hover,a:visited {
    color: inherit;
    text-decoration: none;
  }

  .section-title-captionContainer {
    overflow: hidden;
  }

  .section-title-captionContainer {
    overflow: hidden;
  }

  .line{
    overflow: hidden;
  }

  .visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }

  // デフォルトスクロールバー非表示
  ::-webkit-scrollbar {
    display: none;
  }

  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
