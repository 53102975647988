.preloader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    padding: 10.75vh 7.8125vw;
    background: $color-black;
    z-index: 99999;
    pointer-events: auto;

    @include media('<phone') {
        padding: 3.75vh 7.8125vw;
        height: 100svh;
    }
}

.preloader__inner {
    position: relative;
    width: 100%;
    height: 100%;
}

.preloader__contents {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    color: $color-driftwood;
    writing-mode: vertical-rl;
    gap: clamp(20px, 3.125vw, 99rem);

    font-family:$font-tsukuoldminD;
    font-size: $font-14;
    font-weight: 600;
    font-style: normal;
    line-height: 1.3em;
    letter-spacing: 0.125rem;
    @include media('<phone') {
        display: none;
    }
    @media (max-height: 40rem) {
        display: none;
      }
}

// content-1
.preloader__contents-1 {
    opacity: 0;
    gap: clamp(20px, 2.34375vw, 99rem);
}

.preloader__contents-1 .preloader__caption {
    margin-top: 10em;
}

// content-2
.preloader__contents-2 {
    opacity: 0;
}

.preloader__contents-2 .preloader__descriptions>p:nth-child(2) {
    margin-top: 3.2rem;
}

.preloader__contents-2 .preloader__descriptions>p:nth-child(3) {
    margin-top: 10.7rem;
}

.preloader__contents-2 .preloader__caption {
    margin-top: 16.2em;
}

// content-3
.preloader__contents-3 {
    opacity: 0;
}

.preloader__contents-3 .preloader__descriptions>p:nth-child(2) {
    margin-top: 3.9rem;
}

.preloader__contents-3 .preloader__descriptions>p:nth-child(3) {
    margin-top: 8rem;
}

.preloader__contents-3 .preloader__caption {
    margin-top: 13.4em;
}

// content-4
.preloader__contents-4 {
    opacity: 0;
}

.preloader__contents-4 .preloader__descriptions>p:nth-child(2) {
    margin-top: 6.2rem;
}

.preloader__contents-4 .preloader__descriptions>p:nth-child(3) {
    margin-top: 9.4rem;
}

.preloader__contents-4 .preloader__caption {
    margin-top: 19.5em;
}


.preloader__descriptions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.35rem;
}

.preloader__caption {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    white-space: nowrap;
    font-size: $font-12;
}

.preloader__caption-border {
    width: 1px;
    height: 3.33333em;
    background:linear-gradient(180deg, rgba(101, 51, 4, 0) 0%, rgba(101, 51, 4, 0.5) 49.99%, rgba(101, 51, 4, 0) 100%);
}

// progress-bar
.preloader__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    @include media('<phone') {
        bottom: 5svh;
    }
}

.preloader__progress-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    opacity: 0;
}

.preloader__progress-inner .line {
    width: 100%;
}

.preloader__progress-bar {
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, #f39200 75%, rgba(157, 122, 75, 0) 100%);
    opacity: 0.5;
}

.preloader__progress-text {
    align-self: flex-end;
    margin-top: 14px;
    color: $color-driftwood;
    font-family: $font-tsukuoldminD;
    font-style: normal;
    font-weight: 600;
    font-size: $font-11;
    line-height: 17px;
    color: $color-driftwood-light;
    opacity: 0;
}

.preloader__progress-number {
    display: inline-block;
    width: 2.5rem;
    text-align: center;
}