.archives {
    display: flex;
    align-items: center;
    width: fit-content;
    height: 100vh;
    height: 100dvh;
    padding: 8.75vh 0 5vh 0;
    padding: 8.75dvh 0 5dvh 0;
    @include media('<tablet') {
        padding: 13vh 0 5vh 0;
        padding: 13dvh 0 5dvh 0;
    }
    @include media('<phone') {
        height: 100dvh;
    }
    @media (max-height: 40rem) {
        padding: 10px 0 10px 0;
      }
} 

// first-view 
.archives__firstViewWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    font-family: $font-meno;
    font-style: normal;
    font-weight: 400;
    color: $color-black;
    mix-blend-mode: difference;
}

.archives__firstViewContents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;

    @include media('<phone') {
        align-self: flex-start;
        margin-left: 36px;
    }
}

.archives__title {

}

.archives__titletext {
    font-size: $font-140;
    line-height: 94%;
    letter-spacing: -0.04em;
    @include media('<tablet') {
        font-size: $font-120-sectionTitles;
    }
    @include media('<phone') {
        font-size: $font-140;
    }
    
}

.archives__titletext p {
}

.archives__titletext .line:last-child {
    margin-top: -0.2em;
    margin-left: 1.664em;
    padding-right: 0.01em;
}

.archives__titletext span {
    font-style: italic;
}

.archives__descriptions {
    align-self: flex-end;
    margin-right: 2.2em;
    width: fit-content;
    font-family: $font-neue-haas;
    font-size: $font-14;
    line-height: 130%;
    letter-spacing: 0.005em;
    @include media('<laptop-l') {
        margin-right: -0.7em;
    }
    @include media('<laptop') {
        margin-right: -1.9em;
    }
    @include media('<tablet') {
        margin-right: -3em;
    }
    @include media('<phone') {
        margin-right: -5.3em;
    }
}

.archives__details {
    margin-top: 40px;
    font-size: $font-11;
    line-height: 160%;
    letter-spacing: 0.005em;
}

// menu
.archives__menuWrapper {
    width: fit-content;
    height: 100%;
    padding-right: 84.3vw;
    color: $color-black;
    mix-blend-mode: difference;
}

.archives__list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    list-style: none;
}

.archives__item {
    display: flex;
    position: relative;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
    width: 220px;
    height: 100%;
    overflow: hidden;
    transition: opacity 0.5s ease;
    @media (max-height: 28.75rem) {
        width: 160px;
      }
    @include media('<phone') {
        width: 190px;
    }
}

.archives__line {
    position: absolute;
    right: 1px;
    width: 1px;
    height: 100%;
    background: linear-gradient(180deg, $color-black 75%, rgba(24, 24, 24, 0) 100%);
}

.archives__line-first {
    position: revert;
}

.archives__itemContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
}
.archives__itemMask {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: fit-content;
    overflow: hidden;
    margin-right: 15px;
}

.archives__itemTitleNumber {
    font-family: $font-tsukuoldminD;
    font-style: normal;
    font-weight: 600;
    font-size: $font-12;
    line-height: 142%;
    text-align: right;
}

.archives__itemTitle {
    margin-top: 50px;
    font-family: $font-meno;
    font-style: normal;
    font-weight: 400;
    font-size: $font-80-archives;
    line-height: 92%;
    letter-spacing: -0.04em;
    font-feature-settings: 'liga' off, 'calt' off;
    writing-mode: vertical-rl;
    @include media('<tablet') {
        font-size: $font-80-static;
    }
    @include media('<phone') {
        font-size: $font-80-archives;
    }
    
    @media (max-height: 47.5rem) and (min-width: 75rem){
        margin-top: 32px;
        font-size: $font-70-archives;
      }
    @media (max-height: 28.75rem) {
        margin-top: 16px;
        font-size: $font-40-static;
      }
}

// 個別アイテムスタイリング
// 1
.archives__item-1 .archives__itemTitle .line{
    &:last-child {
        margin-top: 1.96em;
    }
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(5){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(7){
            font-style: italic;
        }
    }
}

// 2
.archives__item-2 .archives__itemTitle .line{
    &:last-child {
        margin-top: 2.2em;
    }
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(6){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(1){
            font-style: italic;
        }
        &>span:nth-child(3){
            font-style: italic;
        }
        &>span:nth-child(5){
            font-style: italic;
        }
    }
}

// 3
.archives__item-3 .archives__itemTitle .line{
    &:last-child {
        margin-top: 2.45em;
    }
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(7){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(3){
            font-style: italic;
        }
    }
}

// 4
.archives__item-4 .archives__itemTitle .line{
    &:first-child>p{
        &>span:nth-child(3){
            font-style: italic;
        }
    }
}

// 5
.archives__item-5 .archives__itemTitle .line{
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(4){
            font-style: italic;
        }
        &>span:nth-child(6){
            font-style: italic;
        }
    }
}

// 6
.archives__item-6 .archives__itemTitle .line{
    &:last-child {
        margin-top: 1.55em;
    }
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(5){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(5){
            font-style: italic;
        }
        &>span:nth-child(7){
            font-style: italic;
        }
        &>span:nth-child(10){
            font-style: italic;
        }
    }
}

// 7
.archives__item-7 .archives__itemTitle .line{
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
    }
}

// 8
.archives__item-8 .archives__itemTitle .line{
    &:last-child {
        margin-top: 0.46em;
    }
    &:last-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(4){
            font-style: italic;
        }
        &>span:nth-child(6){
            font-style: italic;
        }
        &>span:nth-child(8){
            font-style: italic;
        }
    }
}

// 9
.archives__item-9 .archives__itemTitle .line{
    &:last-child {
        margin-top: 2.56em;
    }
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(4){
            font-style: italic;
        }
        &>span:nth-child(9){
            font-style: italic;
        }
        &>span:nth-child(12){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(1){
            font-style: italic;
        }
        &>span:nth-child(4){
            font-style: italic;
        }
        &>span:nth-child(7){
            font-style: italic;
        }
        &>span:nth-child(10){
            font-style: italic;
        }
    }
}

// 10
.archives__item-10 .archives__itemTitle .line{
    &:last-child {
        margin-top: 1.45em;
    }
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(4){
            font-style: italic;
        }
        &>span:nth-child(6){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(5){
            font-style: italic;
        }
        &>span:nth-child(8){
            font-style: italic;
        }
        &>span:nth-child(9){
            font-style: italic;
        }
    }
}

// 11
.archives__item-11 .archives__itemTitle .line{
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(4){
            font-style: italic;
        }
    }
}

// 12
.archives__item-12 .archives__itemTitle .line{
    &:first-child>p{
        &>span:nth-child(3){
            font-style: italic;
        }
        &>span:nth-child(6){
            font-style: italic;
        }
    }
}

// 13
.archives__item-13 .archives__itemTitle .line{
    &:last-child {
        margin-top: 1.2em;
    }
    &:first-child>p{
        &>span:nth-child(1){
            font-style: italic;
        }
        &>span:nth-child(3){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(3){
            font-style: italic;
        }
        &>span:nth-child(5){
            font-style: italic;
        }
    }
}

// 14
.archives__item-14 .archives__itemTitle .line{
    &:first-child>p{
        &>span:nth-child(1){
            font-style: italic;
        }
        &>span:nth-child(4){
            font-style: italic;
        }
        &>span:nth-child(6){
            font-style: italic;
        }
    }
}

// 15
.archives__item-15 .archives__itemTitle .line{
    &:last-child {
        margin-top: 3.13em;
    }
    &:first-child>p{
        &>span:nth-child(3){
            font-style: italic;
        }
        &>span:nth-child(5){
            font-style: italic;
        }
        &>span:nth-child(10){
            font-style: italic;
        }
        &>span:nth-child(16){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(4){
            font-style: italic;
        }
        &>span:nth-child(8){
            font-style: italic;
        }
    }
}

// 16
.archives__item-16 .archives__itemTitle .line{
    &:first-child>p{
        &>span:nth-child(1){
            font-style: italic;
        }
        &>span:nth-child(3){
            font-style: italic;
        }
        &>span:nth-child(6){
            font-style: italic;
        }
        &>span:nth-child(11){
            font-style: italic;
        }
    }
}

// 17
.archives__item-17 .archives__itemTitle .line{
    &:last-child {
        margin-top: 1.6em;
    }
    &:first-child>p{
        &>span:nth-child(1){
            font-style: italic;
        }
        &>span:nth-child(4){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(1){
            font-style: italic;
        }
        &>span:nth-child(4){
            font-style: italic;
        }
    }
}

// 18
.archives__item-18 .archives__itemTitle .line{
    &:last-child {
        margin-top: 2.66em;
    }
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(4){
            font-style: italic;
        }
        &>span:nth-child(6){
            font-style: italic;
        }
        &>span:nth-child(8){
            font-style: italic;
        }
        &>span:nth-child(11){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(4){
            font-style: italic;
        }
        &>span:nth-child(8){
            font-style: italic;
        }
    }
}

// 19
.archives__item-19 .archives__itemTitle .line{
    &:last-child {
        margin-top: 1.2em;
        // margin-left: 0.5em;
    }
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(6){
            font-style: italic;
        }
        &>span:nth-child(9){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(5){
            font-style: italic;
        }
        &>span:nth-child(7){
            font-style: italic;
        }
        &>span:nth-child(10){
            font-style: italic;
        }
    }
}

// 20
.archives__item-20 .archives__itemTitle .line{
    &:first-child>p{
        &>span:nth-child(1){
            font-style: italic;
        }
        &>span:nth-child(5){
            font-style: italic;
        }
    }
}

// 21
.archives__item-21 .archives__itemTitle .line{
    &:last-child {
        margin-top: 0.88em;
    }
    &:first-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
    }
    &:last-child>p{
        &>span:nth-child(2){
            font-style: italic;
        }
        &>span:nth-child(7){
            font-style: italic;
        }
    }
}


.archives__itemTitle .line:nth-child(2) {
    margin-right: -0.12em;
    @media (max-height: 40rem) {
        margin-right: -0.12em;
      }
}

.archives__itemTitle >span {
    display: block;
}

.archives__itemTitle span span {
    font-style: italic;
}

.archives__itemAge {
    align-self: flex-end;
    margin-left: 10px;
    font-family: $font-neue-haas;
    font-style: normal;
    font-weight: 400;
    font-size: $font-11;
    line-height: 150%;
    letter-spacing: 0.05em;
    writing-mode: vertical-rl;
}

.archives__image {
    display: none;
}

.archives__image img{
    width: 100%;
    height: 100%;
}

.archives__image-7 {
    width: 17.1875vw;
    height: 28.90625vw;
}

.archives__image-7 img{
    width: 17.1875vw;
    height: 28.90625vw;
}

.archives__image-8 {
    width: 17.1875vw;
    height: 26.5625vw;
}

.archives__image-9{
    width: 23.4375vw;
    height: 18.90625vw;
}

.archives__image-9 img{
    width: 23.4375vw;
    height: 18.90625vw;
}

.archives__image-10 {
    width: 17.1875vw;
    height: 28.90625vw;
}

.archives__image-11 {
    width: 17.1875vw;
    height: 23.4375vw;
}

.archives__image-12 {
    width: 17.1875vw;
    height: 23.4375vw;
}
.archives__image-13 {
    width: 17.1875vw;
    height: 21.875vw;
}
.archives__image-14 {
    width: 23.4375vw;
    height: 17.96875vw;
}
.archives__image-15 {
    width: 17.1875vw; //220
    height: 23.4375vw; //300
}
.archives__image-16 {
    width: 17.1875vw; //220
    height: 30.46875vw; //390
}
.archives__image-17 {
    width: 17.1875vw; //220
    height: 25.78125vw; //330
}
.archives__image-18 {
    width: 23.4375vw; //300
    height: 18.75vw; //240
}
.archives__image-19 {
    width: 17.1875vw; //220
    height: 23.4375vw; //300
}
.archives__image-20 {
    width: 17.1875vw; //220
    height: 18.75vw; //240
}
.archives__image-21 {
    width: 17.1875vw; //220
    height: 30.9375vw; //396
}
.archives__image-22 {
    width: 17.1875vw; //220
    height: 23.4375vw; //300
}
.archives__image-23 {
    width: 17.1875vw; //220
    height: 29.296875vw; //378
}
.archives__image-24 {
    width: 23.4375vw; //300
    height: 17.65625vw; //226
}
.archives__image-25 {
    width: 17.1875vw; //220
    height: 25.78125vw; //330
}
.archives__image-26 {
    width: 17.1875vw; //220
    height: 33.59375vw; //430
}
.archives__image-27 {
    width: 17.1875vw; //220
    height: 21.875vw; //280
}

