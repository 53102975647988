.lastquote {
    width: 100%;
    color: $color-driftwood;
    background: #000;
    position: absolute;
    top: 98vh;
    left: 0;
    z-index: 20;
    padding: 0 30px 0 30px;
    // @media (max-height: 28.75rem) {
    //     position: revert;
    //     top: 0;
    //   }
    @include media('<tablet') {
        position: revert;
        top: 0;
    }
    
}

.lastquote hr {
    height: 1px;
    background: linear-gradient(90deg, #F39200 75%, rgba(157, 122, 75, 0) 100%);
    border: none;
}

.lastquote__wrapper {
    width: 100%;
    padding-top: 280px;
    @include media('<phone') {
        padding-top: 200px;
    }
}

.lastquote__upContainer {
    width: 100%;
    height: 100vh;
    min-height: 37.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media('<tablet') {
       height: revert;
    }
}

.lastquote__upMessages {
    display: flex;
    flex-direction: column;
    font-family: $font-tsukuoldminB;
    font-style: normal;
    font-weight: 600;
    font-size: $font-17;
    line-height: 235%;
    letter-spacing: 0.02em;
    // font-feature-settings: 'pkna' on, 'palt' on, 'vrt2' on, 'hkna' on;
    writing-mode: vertical-rl;
}

.lastquote__upMessage {
    white-space: nowrap;
}

.lastquote__upMessage .line:last-child {
    margin-top: 10.2rem;
}

.lastquote__upCaption {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2.375rem;
    margin-top: 16.3rem;
    writing-mode: vertical-rl;
    font-size: $font-14;
    color: $color-driftwood-dark;
    white-space: nowrap;

    @include media('<phone') {
        margin-right: 2em;
        margin-top: 15em;
    }
}

.lastquote__upCaptionBorder {
    width: 1px;
    height: 5rem;
    background: linear-gradient(180deg, rgba(101, 51, 4, 0) 0%, #653304 49.99%, rgba(101, 51, 4, 0) 100%);
}

.lastquote__bottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 70px 0;
    @include media('<tablet') {
        padding: 150px 0 70px 0;
    }
    @include media('<phone') {
        padding: 0 0;
    }
}

.lastquote__bottomMessages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
}

.lastquote__bottomMessage {
    margin-right: 13.3em;
    font-family: $font-neue-haas;
    font-style: normal;
    font-weight: 400;
    font-size: $font-14;
    line-height: 170%;
    letter-spacing: 0.05em;

    @include media('<tablet') {
        margin-right: 5.3em;
    }
    @include media('<phone') {
        margin-top: 100px;
        margin-right: 0;
    }
}

.lastquote__bottomMessage .line:last-child {
    margin-left: 11.3em;
    @include media('<phone') {
        margin-left: 4em;
    }
}

.lastquote__bottomCaption {
    align-self: flex-end;
    color: $color-driftwood-dark;
    font-family: $font-neue-haas;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.05em;
    font-size: $font-13;
}

.lastquote__bottomCaption .line {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4em;
    @include media('<phone') {
        margin-top: 30px;
    }
}

.lastquote__bottomCaptionBorder {
    width: 5rem;
    height: 1px;
    background:linear-gradient(90deg, rgba(101, 51, 4, 0) 0%, #653304 49.99%, rgba(101, 51, 4, 0) 100%);
}

.nakaguro {
    color: transparent;
    display: block;
    position: relative;
    }
.nakaguro::before {
    content: "";
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: $color-driftwood-dark;
    margin: auto;
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    left: 0;
    @include media('<phone') {
        right: 0px;
    }
}