.project-detail__wrapper picture{
    opacity: 0;
}

.project-detail__wrapper {
    display: flex;
    align-items: center;
    width: fit-content;
    height: 100vh;
    color: $color-driftwood;
    background: #000;
    @include media('<tablet') {
        height: 100dvh;
    }
}

.horizontal-scroll {
    display: flex;
    align-items: center;
    height: 100%;
}
.project-detail-firstView {
    display: flex;
    align-items: center;
    height: 100%;
}

.project-detail-firstView__wrapper  {
    display: flex;
    justify-content: space-between;
    width: 125vh;
    height: 100%;
    @include media('<tablet') {
        width: fit-content;
        padding-right: 60px;
        flex-direction: column;
    }
}

.project-detail-firstView__left  {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding-top: 20px;
    padding-left: 30px;
    padding-bottom: 30px;
    @include media('<tablet') {
        justify-content: flex-end;
        padding-top: 138px;
        padding-left: 20px;
        padding-bottom: 0px;
    }
}

.project-detail-firstView__left>.line {
    line-height: 1;
}

.project-detail-firstVIew__backButtonContainer {
    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 10;
    @include media('<tablet') {
        left: 20px;
    }
}

.project-detail-firstVIew__backButton  {
    cursor: pointer;
    background: none;
    border: none;
    font-family: $font-neue-haas;
    font-style: normal;
    font-weight: 400;
    font-size: $font-12;
    line-height: 1.2;
    letter-spacing: 0.05em;
    color: $color-driftwood;
    padding: 0;
    margin: 0;
    line-height: 1;
}

.project-detail-firstVIew__backButton a {
    display: flex;
    align-items: center;
    gap: 12px;
}

.project-detail-firstVIew__backArrow {
    position: relative;
    display: flex;
    overflow: hidden;
}

.project-detail-firstVIew__backArrow p:last-child {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
}

.project-detail-firstView__mainTitle  {
    font-family: $font-meno;
    font-size: $font-160detail;
    font-weight: 400;
    font-style: normal;
    line-height: 90%;
    letter-spacing: -0.04em;
    text-transform:uppercase;
}

.project-detail-firstView__mainTitle-1 .line:nth-child(2) {
    margin-top: -0.15em;
    margin-left: 1.63em;
}

.project-detail-firstView__mainTitle span{
    font-style: italic;
}

.project-detail-firstView__right  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
    padding-right: 30px;
    padding-bottom: 30px;
    @include media('<tablet') {
        padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }
}

.project-detail-firstView__descriptions  {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: $font-neue-haas;
    font-weight: 450;
    font-size: $font-11;
    line-height: 110%;
    letter-spacing: 0.005em;
    color: $color-driftwood-dark;
}

.project-detail-firstView__sign {
    background: none;
    border: none;
    @include media('<tablet') {
       padding: 0;
       margin-top: 40px;
    }
}

.project-detail-firstView__sign  .line{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    font-family: $font-neue-haas;
    font-weight: 450;
    font-size: $font-11;
    line-height: 110%;
    letter-spacing: 0.005em;
    color: $color-driftwood-light;
    @include media('<tablet') {
        justify-content: flex-start;
    }
}

.project-detail-firstView__toProjectArrow {
    position: relative;
    display: flex;
    overflow: hidden;
}

.project-detail-firstView__toProjectArrow p:last-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.project-detail-firstView__hero  {
    width: 152.5vh;
    height: 100vh;
    @include media('<tablet') {
        align-self: center;
        width: 88.3151vh;
        height: 57.8804vh;
        width: 88.3151dvh;
        height: 57.8804dvh;
    }
}

.project-detail-firstView__hero picture {
    width: 100%;
    height: 100%;
}

.project-detail-gallery {
    display: flex;
    align-items: center;
    width: fit-content;

    height: 100%;
}

.project-detail-gallery-1st {
    height: 100%;
}

.project-detail-gallery__1stWrapper  {
    display: flex;
    align-items: center;
    gap: 8.75vh;
    height: 100%;
    padding: 20px 18.75vh;
}

.project-detail-gallery__1stImage1 {
    width: 76.25vh;
    height: 76.25vh;
    @include media('<tablet') {
        width: 44.29vh;
        height: 44.29vh;
        width: 44.29dvh;
        height: 44.29dvh;
    }
}

.project-detail-gallery__1stImage2 {
    width: 76.25vh;
    height: 85vh;
    align-self: flex-start;
    @include media('<tablet') {
        width: 44.29vh;
        height: 49.18vh;
        width: 44.29dvh;
        height: 49.18dvh;
        align-self: center;
    }
}

.project-detail-gallery-2nd {
    height: 100%;
}

.project-detail-gallery__2ndWrapper  {
    height: 100%;
    @include media('<tablet') {
        display: flex;
        align-items: center;
    }
}

.project-detail-gallery__2ndImage {
    width: 118.75vh;
    height: 100vh;
    @include media('<tablet') {
        width: 68.75vh; //736
        height: 57.88vh;
        width: 68.75dvh; //736
        height: 57.88dvh;
    }
}

.project-detail-gallery-3rd {
    height: 100%;
}

.project-detail-gallery__3rdWrapper  {
    height: 100%;
    padding-left: 18.75vh;
    @include media('<tablet') {
        display: flex;
        align-items: center;
    }
}

.project-detail-gallery__3rdImage {
    width: 152.5vh;
    height: 100vh;
    @include media('<tablet') {
        width: 88.315vh; //736
        height: 57.88vh;
        width: 88.315dvh; //736
        height: 57.88dvh;
    }
}

.project-detail-gallery-4th {
    height: 100%;
}

.project-detail-gallery__4thWrapper  {
    display: flex;
    align-items: flex-end;
    gap: 8.75vh;
    height: 100%;
    padding: 20px 18.75vh;
    @include media('<tablet') {
        align-items: center;
    }
}

.project-detail-gallery__4thImage1 {
    width: 97.5vh;
    height: 72.75vh;
    align-self: flex-start;
    @include media('<tablet') {
        align-self: center;
        width: 56.521vh; //736
        height: 40.76vh;
        width: 56.521dvh; //736
        height: 40.76dvh;
    }
}

.project-detail-gallery__4thImage2 {
    width: 70vh;
    height: 67.5vh;
    @include media('<tablet') {
        width: 40.4891vh; //736
        height: 39.1304vh;
        width: 40.4891dvh; //736
        height: 39.1304dvh;
    }
}

.project-detail-gallery-5th {
    height: 100%;
}

.project-detail-gallery__5thWrapper  {
    padding-right: 18.75vh;
    height: 100%;
    @include media('<tablet') {
        display: flex;
        align-items: center;
    }
}

.project-detail-gallery__5thImage {
    width: 100vh;
    height: 100vh;
    @include media('<tablet') {
        width: 57.8804vh; //736
        height: 57.8804vh;
        width: 57.8804dvh; //736
        height: 57.8804dvh;
    }
}

.project-detail-gallery__5thImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-detail-gallery-6th {
    height: 100%;
}

.project-detail-gallery__6thWrapper  {
    height: 100%;
    @include media('<tablet') {
        display: flex;
        align-items: center;
    }
}

.project-detail-gallery__6thImage {
    width: 181.25vh;
    height: 100vh;
    @include media('<tablet') {
        width: 105.163vh; //736
        height: 57.8804vh;
        width: 105.163dvh; //736
        height: 57.8804dvh;
    }
}

.project-detail-gallery__6thImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-detail-lastView {
    display: flex;
    align-items: center;
    height: 100%;
    @include media('<tablet') {
        padding: 20px 50px 80px 20px;
    }
}

.project-detail-lastView__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 133.125vh;
    height: 100%;
    @include media('<tablet') {
        gap: 50px;
        align-items: flex-end;
        width: max-content;
    }
}

.project-detail-lastView__left {
    padding: 20px 40px 20px 20px;
    @include media('<tablet') {
        padding: 0;
    }
}

.project-detail-lastView__descriptions  {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: $font-neue-haas;
    font-weight: 450;
    font-size: $font-12;
    line-height: 140%;
    letter-spacing: 0.005em;
    color: $color-driftwood-dark
}

.project-detail-lastView__right {
    align-self: flex-end;
    padding: 20px 40px 20px 20px;
    @include media('<tablet') {
        align-self: center;
        padding: 0;
    }
}

.project-detail-lastView__nextCaption {
    font-family: $font-neue-haas;
    font-weight: 450;
    font-size: $font-11;
    line-height: 110%;
    letter-spacing: 0.005em;
    color: $color-driftwood-dark;
    @include media('<tablet') {
        display: none;
    }
}

.project-detail-lastView__nextTitle {
    margin-top: 10px;
    font-family: $font-meno;
    font-size: $font-120detail;
    font-weight: 400;
    font-style: normal;
    line-height: 90%;
    letter-spacing: -0.04em;
    text-transform:uppercase;
    @include media('<tablet') {
        display: none;
    }
}

.project-detail-lastView__nextCaption-mobile {
    display: none;
    font-family: $font-neue-haas;
    font-weight: 450;
    font-size: $font-12;
    line-height: 110%;
    letter-spacing: 0.005em;
    color: $color-driftwood-dark;
    @include media('<tablet') {
        display: flex;
        align-items: center;
        gap: 14px;
    }
}

.project-detail-lastView__nextArrow {
    width: 20px;
}

.project-detail-lastView__nextTitle span {
    font-style: italic;
}

.project-detail-lastView__nextTitle-1 .line:nth-child(2) {
    margin-top: -0.14em;
    margin-left: 1.26em;
}

.project-detail-lastView__nextTitle-1 .line:nth-child(3) {
    margin-top: -0.14em;
    margin-left: 0.97em;
}

.project-detail-lastView__nextHero  {
    width: 75vh;
    height: 100vh;
    @include media('<tablet') {
        display: none;
    }
    
}

.project-detail-lastView__nextButton__button  {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}

.project-detail-lastView__image {
    width: 100%;
    height: 100%;
}

.project-detail-lastView__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

// project-detail2
.project-detail-firstView__mainTitle-2 .line:first-child {
    margin-left: 0.66em;
}

.project-detail-firstView__mainTitle-2 .line:nth-child(2) {
    margin-top: -0.15em;
}

.project-detail-firstView__mainTitle-2 .line:nth-child(3) {
    margin-top: -0.15em;
    margin-left: 0.985em;
}

.project-detail-lastView__nextTitle-2 .line:first-child {

}

.project-detail-lastView__nextTitle-2 .line:nth-child(2) {
    margin-top: -0.15em;
    margin-left: 0.46em;
}

// project-detail3
.project-detail-firstView__mainTitle-3 .line:nth-child(2) {
    margin-top: -0.15em;
    margin-left: 0.48em;
}

.project-detail-lastView__nextTitle-3 .line:first-child {

}

.project-detail-lastView__nextTitle-3 .line:nth-child(2) {
    margin-top: -0.15em;
    margin-left: -0.4em;
}

.project-detail-lastView__nextTitle-3 .line:nth-child(3) {
    margin-top: -0.15em;
    margin-left: 2.53em;
}

// project-detail4
.project-detail-firstView__mainTitle-4 .line:first-child {
    margin-left: 0.42em;
}

.project-detail-firstView__mainTitle-4 .line:nth-child(2) {
    margin-top: -0.16em;
}

.project-detail-firstView__mainTitle-4 .line:nth-child(3) {
    margin-top: -0.16em;
    margin-left: 2.94em;
}

.project-detail-lastView__nextTitle-4 .line:first-child {

}

.project-detail-lastView__nextTitle-4 .line:nth-child(2) {
    margin-top: -0.13em;
    margin-left: 0.83em;
}

// project-detail5
.project-detail-firstView__mainTitle-5 .line:nth-child(2) {
    margin-top: -0.16em;
    margin-left: 0.78em;
}

.project-detail-lastView__nextTitle-5 .line:first-child {

}

.project-detail-lastView__nextTitle-5 .line:nth-child(2) {
    margin-top: -0.15em;
    margin-left: 2em;
}

// project-detail6
.project-detail-firstView__mainTitle-6 .line:nth-child(2) {
    margin-top: -0.15em;
    margin-left: 1.99em;
}

.project-detail-lastView__nextTitle-6 .line:nth-child(2) {
    margin-top: -0.15em;
    margin-left: 1.635em;
}