.hero {
    margin-top: 40vh;
}

.hero__wrapper {
    position: relative;
    width: 100%;
}

.hero__right {
    display: flex;
    flex-direction: column;
    font-size: $font-276;
    padding-top: 1.27em;
    @include media('<tablet') {
        align-self: flex-end;
        margin-top: -0.2em;
        padding-top: 0em;
    }
    @media (max-width: 68.75rem) and (min-width:  47.5rem) {
      }
    @include media('<phone') {
        align-items: flex-end;
        align-self: flex-end;
        margin-top: -9%;
        margin-left: 0;
        gap: 65px;
    }
}

.hero__nameAgeContainer {
    display: flex;   
    gap: 1.7vw;  
    @include media('<tablet') {
        align-items: flex-end;
    }
}


.hero__wrapper .line p span{
    font-style: italic;
}

.hero__title-wrapper {
    display: flex;
    gap: 1vw;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    @include media('<tablet') {
        flex-direction: column;
        align-items: center;
        top: 30%;
    }
    @include media('<phone') {
        flex-direction: column;
        align-items: flex-start;
        top: 40%;
        width: 100%;
    }
}

.hero__title {
    color: $color-driftwood;
    display:flex;
    flex-direction: column;
    font-family: $font-meno;
    font-style: normal;
    font-weight: 400;
    font-size: $font-276;
    min-height: 0vh;
    line-height: 90%;
    width: 66.0625%;
    letter-spacing: -0.95rem;
    @include media('<tablet') {
        align-self: center;
        font-size: $font-276-static;
        width: 100%;
    }
    @include media('<phone') {
        align-self: flex-start;
        font-size: $font-276;
        width: 100%;
    }
}

.hero__title-1 {
    position: relative;
    overflow: hidden;
    @include media('<tablet') {
        letter-spacing: -0.05em;
    }

}

.hero__title-1 .line {
    width: 100%;
    overflow: hidden;
}

.hero__title-2 {
    position: relative;
    margin-top: -0.18em;
    margin-left: 1.25em;
    overflow: hidden;
    letter-spacing: -0.07em;
    white-space: nowrap;
    @include media('<laptop-s') {
        margin-left: 1.1em;
    }
    @include media('<tablet') {
        margin-left: 0.78em;
    }
    @include media('<phone') {
        margin-left: 0;
        align-self: flex-end;
        margin-top: -0.16em;
    }
}

.hero__title-2 .line {
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include media('<phone') {
        padding-right: 0.07em;
    }
}

.hero__name {
    color: $color-driftwood;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 0.4em;
    font-family: $font-meno;
    font-style: normal;
    font-weight: 400;
    font-size: $font-30;
    min-height: 0vh;
    line-height: 90%;
    letter-spacing: -0.125rem;
    @include media('<tablet') {
        font-size: $font-30-static;
        margin-bottom: 1.3em;
    }
    @include media('<phone') {
        margin-top: 0;
        margin-bottom: 0.8em;
        font-size: $font-30;
    }
}

.hero__nameTop {
    margin-left: 2.05em;
    overflow: hidden;
}

.hero__nameTop .line {
    overflow: hidden;
}

.hero__nameBottom {
    margin-top: -0.1em;
}

.hero__nameBottom .line {
    overflow: hidden;
}

.hero__age {
    color: $color-driftwood;
    font-family: $font-meno;
    font-style: normal;
    font-weight: 400;
    font-size: $font-276;
    min-height: 0vh;
    line-height: 90%;
    letter-spacing: -0.02em;
    @include media('<tablet') {
        font-size: $font-276-static;
        margin-bottom: 0em;
    }
    @include media('<phone') {
        font-size: $font-276;
        margin-bottom: 0em;
    }
}

.hero__age .line {
    overflow: hidden;
}

.experience
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

.experienceHero
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -2;
}

.experienceDetail
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    pointer-events: none;
}